import axios from "axios";
import {Client} from '@stomp/stompjs';

const handlers = [];

const webSocketUrl =
    process.env.NODE_ENV === 'development'
        ? `ws://${window.location.hostname}:9090/gs-guide-websocket`
        : `wss://${window.location.hostname}/gs-guide-websocket`;

// Specify headers
// let headers = {
//     'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJuYXRhbGllQG1hcmtvdi5jb20iLCJleHAiOjE3MDcxMTg4ODIsImlhdCI6MTcwNzEwMDg4Mn0.CUcmhBrjL1mzsc6cmdAPRzS3Vh6gNTKyLT-1Gx21cJpvrgWTxKau703ePO_zgKtGhcpICpqJnFuv6LNWsz6b1g'
// };

const headers = {};


let client = new Client({
    brokerURL: webSocketUrl,
    debug: function (str) {
        console.log('STOMP Debug: ', str);
    },
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,

});


client.onConnect = (frame) => {
    console.log('WebSocket is Connected: ', frame);

    client.subscribe('/topic/activity', (message) => {
        if (message.body) {
            console.log('Received Message: ', message.body);
            handlers.forEach((handler) => {
                handler(JSON.parse(message.body))
            });
        }
    });
};

client.onStompError = (frame) => {
    console.log('Broker Reported Error: ' + frame.headers['message']);
    console.log('Additional Details: ' + frame.body);
};

client.activate();

export default {
    addHandler(handler) {
        handlers.push(handler);
    },
}
