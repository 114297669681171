import backendAPI from "@/api/backend-api";
import moment from "moment/moment";

const state = {
    apollos: []

};

const mutations = {
    ADD_ALL(state, apollos) {
        state.apollos = Object.values(apollos);
    },
    UPDATE_APOLLO(state, apollo) {
        const updateIndex = state.apollos.findIndex(
            (item) => item.id === apollo.id
        );
        console.log("Found Index: ", updateIndex);
        state.apollos[updateIndex].email = apollo.email;
    },

};

const getters = {
    getLeadsByDomainName: (state) => (domainName) => {

        return state.apollos.filter(
            (c) =>
                c.domain_name
                &&
                domainName.toLowerCase() === c.domain_name.toLowerCase()
        );
        // return state.apollos;
    },
};

const actions = {

    async loadLeadsByDomainName({commit, state}, domainName) {
        const response = await backendAPI.loadApolloLeads(domainName);
        let leads = await response.data;
        leads.forEach(lead => {lead.domain_name = domainName;});
        commit("ADD_ALL", leads);

    },

    async findApolloLeads({commit, state}, domainName) {
        const response = await backendAPI.findApolloLeads(domainName);
        let leads = await response.data;
        leads.forEach(lead => {lead.domain_name = domainName;});
        commit("ADD_ALL", leads);

    },

    async enrichApolloById({commit}, id) {
        console.log("Getting apollo by id:", id);

        let response = await backendAPI.getApolloLead(id);
        commit("UPDATE_APOLLO", response.data);
    },


};


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
};
