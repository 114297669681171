<template>
    <v-card
            hover
            class="elevation-4 mt-3"
            dense
            rounded
            :loading="loading"
    >
        <v-card-subtitle
                @click="retrieveConversation"
                class="pa-0"
        >
            <div v-if="lastMessage"
                    :class="getClass(lastMessage)"
            >

                <div class="d-flex flex-wrap justify-space-around">
                    <div class="ma-1">
                        <domain-menu :name="lastMessage.domain"/>
                    </div>
                    <div class="ma-1">
                        <sibling-menu :name="lastMessage.sibling" :phone="lastMessage.phone"/>
                    </div>
                    <div class="ma-1">
                        <domain-card :domain-name="lastMessage.domain"/>
                    </div>
                    <div class="ma-1">
                        <leads-card-dialog :domain-name="lastMessage.domain"/>
                    </div>
                    <div class="ma-1">
                        <evaluation-menu :domain-name="lastMessage.domain"
                                         :suggested-price="lastMessage.suggestedPrice"/>
                    </div>
                    <div class="ma-1">
                        <v-btn color="primary" class="d-flex justify-center">
                            <date-display :date="lastMessage.dropDate" date-format="YYYY MMM DD"></date-display>
                        </v-btn>
                    </div>
                    <div class="ma-1">
                        <p class="font-weight-medium">
                            {{ lastMessage.phone }}
                        </p>
                    </div>
                    <div class="ma-1">
                        {{ lastMessage.conversationStage }}
                    </div>

                    <div class="d-flex ma-1">
                        <v-btn tile class="mx-1" @click.stop="block">
                            <v-icon small>mdi-stop</v-icon>
                        </v-btn>

                        <div v-if=" lastMessage.conversationStage === 'backorder'">
                            <backorder-menu-small
                                    :domain="lastMessage.domain"
                            />
                        </div>
                        <div v-if=" lastMessage.conversationStage === 'pending sale'">

                            <list-domain-menu-small
                                    :domain-name="lastMessage.domain"
                            />
                        </div>
                        <div>

                            <new-lead-menu-small
                                    :conversation-id="lastMessage.conversationId"
                            />
                        </div>
                        <v-btn tile class="mx-1" @click.stop="markAnswered">
                            <v-icon small>mdi-read</v-icon>
                        </v-btn>

                    </div>
                </div>
                <v-container class="d-flex justify-center">
                    <v-col class="text-container">
                        {{ lastMessage.sibling }}: {{ lastMessage.text }}</v-col>

                </v-container>
            </div>

        </v-card-subtitle>

        <v-card-text
                class="pa-0"
                v-if="!hidden"

        >
            <conversation-tab
                    :conversation="conversation"
                    :show-form="true"
                    :kind="conversation && conversation.stage==='pending sale'?'sales':'response'"
                    @collapse="hidden = true"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import ConversationTab from "@/components/conversations/ConversationTab";
import DateDisplay from "@/components/DateDisplay";
import EvaluationMenu from "@/components/EvaluationMenu";
import BackorderMenuSmall from "@/components/BackorderMenuSmall";
import ListDomainMenuSmall from "@/components/ListDomainMenuSmall";
import NewLeadMenuSmall from "@/components/NewLeadMenuSmall";
import DomainMenu from "@/components/DomainMenu";
import SiblingMenu from "@/components/SiblingMenu.vue";
import DomainCard from "@/components/domains/DomainCard.vue";
import LeadsCardDialog from "@/components/leads/LeadsCardDialog.vue";


export default {
    name: "MessageCard",
    components: {
        LeadsCardDialog,
        DomainMenu, SiblingMenu, DomainCard,
        NewLeadMenuSmall, DateDisplay, ConversationTab, EvaluationMenu, BackorderMenuSmall, ListDomainMenuSmall
    },
    props: {
        lastMessage: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            hidden: true,
            loading: false,

        }
    },

    computed: {
        ...mapGetters({
            getConversation: "conversations/getConversationById"
        }),
        conversation() {
            return this.getConversation(this.lastMessage.conversationId)
        }
    },
    methods: {
        ...mapActions(
            "conversations", ["updateConversationAction", "hideConversationAction"]
        ),
        ...mapMutations(
            "messages", ["REMOVE_MESSAGE"]
        ),
        markAnswered() {
            const payload = {
                id: this.lastMessage.conversationId,
                answered: true,
            };
            console.log("Sending update ", payload);
            this.updateConversationAction(payload);
            this.REMOVE_MESSAGE(payload.id);
        },
        block() {
            const payload = {
                id: this.lastMessage.conversationId,
                block: true,
            };
            console.log("Blocking ", payload);
            this.hideConversationAction(payload);
            this.REMOVE_MESSAGE(this.lastMessage.conversationId);
        },
        getClass(message) {
            let style = this.pastCutOff(message) ? "pastcutoff"
                : message.conversationStage === "not interested" ? "not-interested"
                    : message.answered ? "answered" : "not-answered";
            return style;
        },

        pastCutOff(message) {
            return message.dropDate && new Date(this.lastMessage.dropDate) < new Date();
        },


        async retrieveConversation() {
            if (this.hidden && !this.conversation) {
                this.loading = true;
                await this.$store.dispatch(
                    "conversations/loadConversationByIdAction",
                    this.lastMessage.conversationId
                );
                this.loading = false;
            } else {
                this.$store.commit("conversations/SET_ACTIVE_CONVERSATION_ID", null);
            }
            this.hidden = !this.hidden;

        },
    }
}
</script>

<style scoped>

#domain_name {
    max-width: 100%;
}

.dropDate {
    background-color: #a7ffeb;
}

.answered {
    background-color: #4caf50;
}

.not-answered {
    background-color: rgb(208, 169, 169);
}

.pastcutoff {
    background-color: rgb(255, 242, 0);
}

.not-interested {
    background-color: #5e8a9e;
}
.text-container {
  display: block; /* Ensure it's a block element */
  width: 100%; /* Full width of the screen */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  font-size: 16px; /* Set the desired font size */
  font-weight: bold; /* Make text bold */
  line-height: 1.5; /* Adjust line height */
}
</style>
