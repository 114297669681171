<template>
  <v-data-table
      fixed-header
      :height=getTableHeight()
      :headers="backordersHeaders"
      :items="backorders"
      :loading="loading"
      loading-text="Loading domains... Please wait"
      multi-sort
      item-key="id"
      @item-expanded="loadConversations"
      :options="tableOptions"
      :item-class="itemRowBackground"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :expanded.sync="expanded"

  >


    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-container fluid>
        <v-row class="d-flex align-center">
          <v-col cols="1">
            <v-checkbox v-model="approvedFilter" label='Only approved'>
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="junkFilter" label='hide junk'>
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="hasGTLD" label="with gtld">
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="hasGmail" label="gmail">
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="hasPhone" label="phone">
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="hasCloudFlare" label="cloudflare">
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="hasUSWebsiteWithEmailAndPhone" label="US Web Phone & Email">
            </v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="fatSibling" label="Fat Sibling">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols="1">
            <v-text-field v-model="sibsMin" label="Min Sibs">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field v-model="devSibs" label="Dev Sibs">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field v-model="devRels" label="Dev Rels">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field v-model="siblings2Owners" label="Min S/O">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field v-model="ageMin" label="Min Age">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field v-model="lengthMax" label="Max Length">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field v-model="wordsCount" label="Words Count">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
    </template>


    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <leads-card
            :domain-name="item.domainName"
            :reload="true"
            class="px-0 mx-0"
        />
      </td>
    </template>

    <template #[`item.evaluation`]="{ item }">
      <evaluation-menu :domain-name="item.domainName"
                       :suggested-price="item.evaluation"/>
    </template>
    <template #[`item.domainName`]="{ item }">
      <domain-menu :name="item.domainName"/>
    </template>
    <template #[`item.wstats`]="{ item }">
      {{ getWStats(item) }}
    </template>

    <template #[`item.bid`]="{ item }">
      <v-text-field
          prefix="$"
          v-model="item.bid"
          @change="updateBackorder(item)"
          @keydown.enter.prevent="updateBackorder(item)"
      ></v-text-field>
    </template>

    <template #[`item.hasLeadWithEmailAndPhoneOnWebsite`]="{ item }">
      <v-icon v-if="item.hasLeadWithEmailAndPhoneOnWebsite" color="primary">mdi-web</v-icon>
    </template>

    <template #[`item.hasEmailProvided`]="{ item }">
      <v-icon v-if="item.hasEmailProvided" color="primary">mdi-flag</v-icon>
    </template>

    <template #[`item.hasSiblingEmail`]="{ item }">
      <v-icon v-if="item.hasSiblingEmail" color="primary">mdi-email</v-icon>
    </template>


    <template #[`item.orderTime`]="{item}">
      <date-display
          date-format="YYYY MMM DD"
          :date="item.dropCatchOrderTime"/>
    </template>

    <template #[`item.info`]="{ item }">
      <domain-card :domain-name="item.domainName"/>
    </template>
<!--    <template #[`item.textSent`]="{ item }">-->
<!--      <v-btn-->
<!--          fab-->
<!--          x-small-->
<!--          @click="textedBackorder(item)">-->
<!--        <v-icon>-->
<!--          {{ item.textSent ? 'mdi-message-text' : 'mdi-cellphone' }}-->
<!--        </v-icon>-->
<!--      </v-btn>-->
<!--    </template>-->
<!--    <template #[`item.emailSent`]="{ item }">-->
<!--      <v-btn-->
<!--          fab-->
<!--          x-small-->
<!--          @click="emailedBackorder(item)">-->
<!--        <v-icon>-->
<!--          {{ item.emailSent ? 'mdi-email' : ' mdi-email-outline' }}-->
<!--        </v-icon>-->
<!--      </v-btn>-->
<!--    </template>-->


    <template #[`item.actions`]="{ item }">

      <div class="d-flex justify-center">

        <v-btn
            fab
            x-small
            @click.stop="changeStatusBackorder(item)">
          <v-icon>
            mdi-recycle
          </v-icon>
        </v-btn>
        <v-btn
            fab
            x-small
            @click="deleteBackorder(item)">
          <v-icon>
            mdi-trash-can
          </v-icon>
        </v-btn>

        <backorder-menu-small
            :domain="item.domainName"
        />

        <ListDomainMenuSmall
            :domain-name="item.domainName"
        />
        <new-lead-from-inventory
            :domain-name="item.domainName"
            :sibling="item.name"
        />
      </div>
    </template>
    <template #[`item.flags`]="{ item }">

      <div class="d-flex justify-end">
        <p v-if="item.hotWords">H</p>
        <p v-if="item.multiOwner">O</p>
        <p v-if="item.titleMatch">T</p>

      </div>
    </template>
    <template #footer>
      <v-container>
        <v-row class="d-flex justify-space-between">
          <v-col>
            <v-btn
                color="primary"
                dark
                class="ma-2"
                @click="backorderByDate">
              DC Backorder
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                color="primary"
                dark
                class="ma-2"
                @click="copyGNAMEToClipBoard">
              Copy for GNAME
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                color="primary"
                dark
                class="ma-2"
                @click="copySnapBackordersToClipBoard">
              Copy for Snap Backorders
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                color="primary"
                dark
                class="ma-2"
                @click="copyDCToClipBoard">
              Copy for DC
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                color="primary"
                dark
                class="ma-2"
                @click="produceBackorders">
              produce
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

  </v-data-table>

</template>

<script>
import tools from "@/util/tools";
import DomainMenu from "@/components/DomainMenu";
import {mapActions, mapGetters, mapState} from 'vuex'
import DateDisplay from "@/components/DateDisplay";
import ListDomainMenuSmall from "@/components/ListDomainMenuSmall";
import DomainCard from "@/components/domains/DomainCard";
import EvaluationMenu from "@/components/EvaluationMenu";
import LeadsCard from "@/components/leads/LeadsCard.vue";
import BackorderMenuSmall from "@/components/BackorderMenuSmall.vue";
import NewLeadFromInventory from "@/components/NewLeadFromInventory.vue";

export default {
  name: "BackordersTable",
  components: {
    NewLeadFromInventory,
    BackorderMenuSmall,
    LeadsCard,
    DomainCard,
    DateDisplay,
    DomainMenu,
    ListDomainMenuSmall,
    EvaluationMenu
  },
  data() {
    return {
      search: "",
      calendar: false,
      tableOptions: {
        itemsPerPage: 200
      },
      expanded: [],

      backordersHeaders: [
        /*        {text: "Email", value: "emailSent"},
                {text: "SMS", value: "textSent"},*/
        {text: "Flags", value: "flags", sortable: false},
        {text: "Actions", value: "actions", sortable: false},
        {text: "Info", value: "info", sortable: false},
        {text: "Domain", value: "domainName"},
        {text: "Evaluation", value: "evaluation"},
        {text: "WStats", value: "wstats", width: "50px"},
        {text: "Bid", value: "bid", width: "75px"},
        {text: "Website", value: "hasLeadWithEmailAndPhoneOnWebsite"},
        {text: "Max Emp", value: "maxEmployees"},
        {text: "Dev Sib", value: "developedSiblingsCount"},
        {text: "Dev Rel", value: "developedRelativesCount"},
        {text: "Owners", value: "ownersCount"},
        {text: "Spell", value: "spelling"},
        {text: "Google", value: "googleExactSearchResults"},
        // {text: "Ads", value: "googleAdsCount", hidden: true},
        {text: "Age", value: "age"},
        // {text: "Words", value: "wordCount"},
        // {text: "Length", value: "nameLength"},
        {text: "Sibs", value: "siblingsCount"},
        {text: "Rels", value: "relativesCount"},
        // {text: "Provided Email", value: "hasEmailProvided"},
        {text: "Domain Rank", value: "domainRank", sortable: true},
        {text: "Page Rank", value: "pageRank", sortable: true},
        {text: "Ext Links", value: "extLinks", sortable: true},
        {text: "Sibling Email", value: "hasSiblingEmail"},
      ],
    }
  },
  computed: {
    ...mapGetters({snapBackordersText: 'backorders/getSnapBackordersText'}),
    ...mapGetters({gnameBulkText: 'backorders/getGNAMEBulkText'}),
    ...mapGetters({dcBackordersText: 'backorders/getDCBackordersText'}),
    ...mapState("backorders", ["loading", "filterJunk"]),
    ...mapGetters({
      conversations: "conversations/getConversationsByDomainName"
    }),
    ...mapGetters({
      backorders: "backorders/getSortedBackorders",
    }),
    hasUSWebsiteWithEmailAndPhone: {
      get() {
        return this.$store.state.backorders.hasUSWebsiteWithEmailAndPhone
      },
      set(val) {
        this.$store.commit("backorders/SET_HAS_US_WEB_WITH_PHONE_AND_EMAIL", val);
      }
    },

    fatSibling: {
      get() {
        return this.$store.state.backorders.fatSibling
      },
      set(val) {
        this.$store.commit("backorders/SET_FAT_SIBLING", val);
      }
    },
    junkFilter: {
      get() {
        return this.$store.state.backorders.filterJunk;
      },
      set(val) {
        this.$store.commit("backorders/SET_JUNK_FILTER", val);
      },
    },
    hasGTLD: {
      get() {
        return this.$store.state.backorders.hasGTLD;
      },
      set(val) {
        this.$store.commit("backorders/SET_HAS_GTLD", val);
      },
    },
    hasGmail: {
      get() {
        return this.$store.state.backorders.hasGmail;
      },
      set(val) {
        this.$store.commit("backorders/SET_HAS_GMAIL", val);
      },
    },
    hasPhone: {
      get() {
        return this.$store.state.backorders.hasSiblingPhone;
      },
      set(val) {
        this.$store.commit("backorders/SET_HAS_SIBLING_PHONE", val);
      },
    },
    hasCloudFlare: {
      get() {
        return this.$store.state.backorders.hasCloudFlare;
        // return this.$route.params.cloudFlare
      },
      set(val) {
        this.$store.commit("backorders/SET_HAS_CLOUDFLARE", val);
        // this.$router.replace({ query: { cloudFlare: val } })

      },
    },
    approvedFilter: {
      get() {
        return this.$store.state.backorders.approvedOnly;
      },
      set(val) {
        this.$store.commit("backorders/SET_APPROVED_ONLY", val);
      },
    },
    sibsMin: {
      get() {
        return this.$store.state.backorders.sibsMin;
      },
      set(val) {
        this.$store.commit("backorders/SET_SIBS_MIN", val);
      },
    },
    devSibs: {
      get() {
        return this.$store.state.backorders.devSibs;
      },
      set(val) {
        this.$store.commit("backorders/SET_DEV_SIBS", val);
      },
    },
    devRels: {
      get() {
        return this.$store.state.backorders.devRels;
      },
      set(val) {
        this.$store.commit("backorders/SET_DEV_RELS", val);
      },
    },
    ageMin: {
      get() {
        return this.$store.state.backorders.ageMin;
      },
      set(val) {
        this.$store.commit("backorders/SET_AGE_MIN", val);
      },
    },
    wordsCount: {
      get() {
        return this.$store.state.backorders.wordsCount;
      },
      set(val) {
        this.$store.commit("backorders/SET_WORDS_COUNT", val);
      },
    },
    siblings2Owners: {
      get() {
        return this.$store.state.backorders.siblingsToOwnersMin;
      },
      set(val) {
        this.$store.commit("backorders/SET_S2O_MIN", val);
      },
    },
    lengthMax: {
      get() {
        return this.$store.state.backorders.lengthMax;
      },
      set(val) {
        this.$store.commit("backorders/SET_LENGTH_MAX", val);
      },
    },
  },
  methods: {
    ...mapActions("domains", ["loadBackordersPageAction"]),
    ...mapActions({backorderByDate: 'backorders/backorderByDate'}),
    ...mapActions({produceBackorders: 'backorders/produceBackorders'}),
    ...mapActions({deleteBackorder: 'backorders/deleteBackorder'}),
    ...mapActions({updateBackorder: 'backorders/updateBackorder'}),
    ...mapActions("conversations", ["loadConversationsByDomainNameAction"]),


    changeStatusBackorder(item) {
      item.approved = item.approved == null ? false : !item.approved;
      this.updateBackorder(item)
    },

    itemRowBackground(item) {
      if (item.approved) return "approved";
      if (item.approved === false) return "disapproved";

      const lastYear = tools.subtractYears(1);
      const dropCatchBackorderTime = item.dropCatchOrderTime ? new Date(item.dropCatchOrderTime) : null;
      return dropCatchBackorderTime ? lastYear > dropCatchBackorderTime ? "last-year" : "ordered" : "not-ordered";
    },
    getTableHeight() {
      return window.innerHeight - 200;
    },

    getWStats(item) {

      return item.wordStats;
    },

    async loadConversations(event) {
      console.log("Loading conversations for item", event.item.domainName, event.value);

      if (event.value) {
        await this.loadConversationsByDomainNameAction(event.item.domainName);
      }
    },

    copySnapBackordersToClipBoard() {
      tools.copyTextToClipboard(this.snapBackordersText);
      this.$store.dispatch("snackbar/setSnackbar", {
        data: {
          "Copied": this.snapBackordersText,
        },
        color: "blue",
      });
    },
    copyGNAMEToClipBoard() {
      tools.copyTextToClipboard(this.gnameBulkText);
      this.$store.dispatch("snackbar/setSnackbar", {
        data: {
          "Copied": this.gnameBulkText,
        },
        color: "blue",
      });
    },
    copyDCToClipBoard() {
      tools.copyTextToClipboard(this.dcBackordersText);
      this.$store.dispatch("snackbar/setSnackbar", {
        data: {
          "Copied": this.dcBackordersText,
        },
        color: "blue",
      });
    }
  },

  mounted() {
    const searchFilter = {
      dropDate: new Date()
    }
    this.$store.dispatch(`${this.$route.name}/search`, searchFilter)
  },

}
</script>

<style>
.disapproved {
  background-color: rgb(161, 42, 42)
}

.approved {
  background-color: rgb(95, 211, 100)
}

.not-ordered {
  background-color: rgb(255, 255, 255)
}

.last-year {
  background-color: rgba(255, 251, 0, 0.62)
}

.ordered {
  background-color: #6aea96;
}
</style>
