<template>
    <v-container fluid v-if="conversation">
        <v-row v-if="conversation.messages">
            <v-col cols="4">
                <data-card :data="conversation" :caption="conversation.phone"></data-card>
            </v-col>
            <v-col cols="4" order="last">
                <v-select
                        dense
                        :value="this.conversation.stage"
                        :items="conversationStages"
                        @change="updateStage"
                        hide-details
                        class="mt-0"
                />
            </v-col>
            <v-col cols="4">
                <sibling-menu v-if="showSiblingMenu" :name="conversation.sibling" :phone="conversation.phone"/>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12">
                <message-list :conversation="conversation" @collapse="$emit('collapse')"/>
                <message-form
                        :conversation="conversation"
                        :kind="kind"
                />
            </v-col>

        </v-row>

    </v-container>
</template>

<script>
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";
import MessageList from "@/components/message/MessageList";
import MessageForm from "@/components/message/MessageForm";
import SiblingMenu from "@/components/SiblingMenu";
import EvaluationMenu from "@/components/EvaluationMenu";
import DomainCard from "@/components/domains/DomainCard";
import DataCard from "@/components/domains/DataCard";
import PhoneMenu from "@/components/PhoneMenu.vue";
import DomainMenu from "@/components/DomainMenu.vue";

export default {
    name: "ConversationTab",
    components: {
        SiblingMenu, DataCard,
        MessageList, MessageForm
    },

    props: ["conversation", "lead", "showSiblingMenu"],


    computed: {
        ...mapState("conversations", ["conversationStages"]),
        ...mapGetters("conversations", ["getConversationByPhone"]),

        kind() {
            return this.conversation.stage == 'pending sale' ? 'sales' : 'response'
        },
    },


    methods: {
        ...mapMutations({
            removeMessage: "messages/REMOVE_MESSAGE"
        }),
        ...mapActions("conversations", [
            "placeCallAction",
            "updateConversationAction",
        ]),
        ...mapActions("messages", ["updateMessageStageAction"]),
        ...mapActions("inventory", ["loadInventoryDomainByNameAction"]),

        updateStage(value) {

            this.updateConversationAction({id: this.conversation.id, stage: value});
            this.updateMessageStageAction({domainName: this.conversation.domain, stage: value});
        },
    },

    async created() {
        if (this.conversation && this.conversation.stage == 'pending sale') {
            console.log("Loading inventory for ", this.conversation.stage);
            this.loadInventoryDomainByNameAction(this.conversation.domain);
        }
    },
};
</script>

<style scoped>

</style>
