import api from "@/api/message-api";
import moment from "moment"

const state = {
    messages: [],
    totalPages: 0,
    totalItems: 0,
    searchTerm: null,
    conversationStageFilter: null,
    dropDate: "",
    messageClassification: [
        "HOW_MUCH",
        "NO_THANKS",
        "PROFANITY",
        "WHO_IS_IT",
        "OWNER",
        "NOT_AN_OWNER",
        "EMAIL",
        "OFFER",
        "EXPENSIVE",
        "FOLLOWUP",
        "AGREEMENT",
        "UNSUBSCRIBE",
        "UNCLASSIFIED",
    ],
    loading: false,
};

const mutations = {

    CLEAR_SEARCH(state) {
        state.searchTerm = null;
        state.conversationStageFilter = null;
        state.notAnswered = false;
        state.dropDate = null;
        state.searched = false;
    },

    UPDATE_MESSAGE_STAGE(state, data) {
        console.log("Updating message for", data)
        let messages = state.messages.filter(m => m.domain === data.domainName);
        if (messages) {
            messages.map(m => m.conversationStage = data.stage);
            console.log("Updated messages", messages);
        }

    },

    UPDATE_MESSAGE_CLASSIFICATION(state, data) {
        console.log("Updating message for", data)
        let message = state.messages.find(m => m.id === data.id);
        if (message) {
            message.classification = data.classification;
        }

    },


    REMOVE_MESSAGE(state, id) {
        state.messages = state.messages.filter(m => m.conversationId !== id);
    },

    ADD_MESSAGES(state, data) {

        console.log("Adding messages", data)

        const messages = state.messages
            .concat(data.messages)
            .reduce((res, val) => {
                res[val.id] = val
                return res
            }, {})

        state.messages = Object.values(messages)

    },

    ADD_MESSAGE(state, data) {
        console.log("Adding message ...", data)
        const messages = state.messages
            .concat(data)
            .reduce((res, val) => {
                res[val.id] = val
                return res
            }, {})

        state.messages = Object.values(messages)


    },

    UPDATE_TOTAL_PAGES(state, totalPages) {
        state.totalPages = totalPages
    },

    SET_LOADING(state, payload) {
        state.loading = payload;
    },

    SET_SEARCH_RESULTS(state, payload) {
        state.messages = payload;
    },


};

const actions = {
    async search({commit, dispatch}, filter) {
        commit("SET_LOADING", true);

        console.log("Searching for ", filter);
        const response = await api.searchForDate(filter).catch(e => {
            commit("SET_LOADING", false);
            if ('Unauthorized' === e.response.data.error) {
                dispatch("logout", null, {root: true});
            }
        });
        if (response.data.length !== 0) {
            commit("SET_SEARCH_RESULTS", response.data.messages);
        }
        commit("SET_LOADING", false);

    },

    async loadNotAnswered({commit, dispatch}) {
        commit("SET_LOADING", true);

        const response = await api.load().catch(e => {
            commit("SET_LOADING", false);
            if ('Unauthorized' === e.response.data.error) {
                dispatch("logout", null, {root: true});
            }
        });
        if (response.data.length !== 0) {
            commit("ADD_MESSAGES", response.data);
        }
        commit("SET_LOADING", false);
    },


    async loadMessagesByDate({commit, dispatch}) {
        commit("SET_LOADING", true);

        const response = await api.loadByDate().catch(e => {
            commit("SET_LOADING", false);
            if ('Unauthorized' === e.response.data.error) {
                dispatch("logout", null, {root: true});
            }
        });
        if (response.data.length !== 0) {
            commit("ADD_MESSAGES", response.data);
        }
        commit("SET_LOADING", false);
    },

    async clear({commit}) {
        commit("CLEAR_SEARCH");
    },

};

const getters = {
    getSortedMessages() {
        let sorted = state.messages
            .sort(({id: a}, {id: b}) => b - a)
            .filter((s => ({conversationId}) => !s.has(conversationId) && s.add(conversationId))(new Set));

        let sortedByTime =  sorted.sort(
            (a, b) =>
                new moment(b.lastUpdated).valueOf() -
                new moment(a.lastUpdated).valueOf()
        );
        return sortedByTime;
    },

    getSearchTerm() {
        return state.searchTerm;
    },
};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
};
