<template>
  <div v-if="status">
      <v-icon
        v-if="status === 'sent'"
      >
        mdi-arrow-up-bold-box-outline
      </v-icon>
      <v-icon
        v-else-if="status === 'delivered'"
        color="green"
      >
        mdi-inbox-arrow-up
      </v-icon>

      <v-icon
        v-else-if="status === 'received'"
        color="blue"
      >
        > mdi-email
      </v-icon>

    <v-icon
        v-else-if="status === 'processed'"
        color="grey"
    >
      > mdi-cog-outline
    </v-icon>
    <v-icon
        v-else-if="status === 'accepted'"
        color="grey"
    >
      > mdi-cog-outline
    </v-icon>

    <v-icon
      v-else-if="status === 'bounce'"
      color="red"
    >
      mdi-wall
    </v-icon>
    <v-icon
        v-else-if="status === 'dropped'"
        color="red"
    >
      mdi-water-alert
    </v-icon>
    <v-icon
        v-else-if="status === 'deferred'"
        color="red"
    >
      mdi-timer-sand-paused
    </v-icon>
    <v-icon
        v-else-if="status === 'spamreport'"
        color="red"
    >
      mdi-alert
    </v-icon>
  </div>
</template>

<script>
export default {
  props: ["status", "error"],
};
</script>

<style></style>
